
















































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiBroadcastPatientExcel,
  ApiBroadcastPatientExcelBefore,
  ApiBroadcastQuestionList,
  ApiBroadcastQuestionDelete,
  ApiBroadcastQuestionSave,
} from "@/apis";
import { breadcrumb } from "@/utils/breadcrumb";

import ConsultationIndex from "@/views/Supervise/Consultation/index.vue";
import { component } from "vue/types/umd";
import moment from "moment";
import { Form } from "element-ui";

@Component({
  components: {},
})
export default class LivePatientIndex extends Vue {
  tableLoading = false;
  exportLoading = false;
  detailDialog = false;
  addLoading = false;
  //直播新增
  addQuestionSave = {
    live_broadcast_name: "",
    live_broadcast_date: "",
    enrollment_max: "",
    doctor_article_id: "",
  } as any;
  addQuestionRules = {
    live_broadcast_name: [
      { required: true, message: "请输入直播名称", trigger: "blur" },
    ],
    live_broadcast_date: [
      { required: true, message: "请输入直播时间", trigger: "blur" },
    ],
    enrollment_max: [
      { required: true, message: "请输入报名人数", trigger: "blur" },
    ],
    doctor_article_id: [
      { required: true, message: "请输入内容id", trigger: "blur" },
    ],
  };
  // 查询条件
  query: any = {
    patient_name: "",
    doctor_name: "",
    title: "",
    live_broadcast_date_end: "",
  };
  // 类型
  typeList: Array<any> = [
    { value: null, label: "全部" },
    { value: "101", label: "嘉医云诊室小程序" },
  ];
  moduleList: Array<any> = [
    { value: null, label: "全部" },
    { value: "100", label: "科普" },
    { value: "101", label: "视频" },
    { value: "102", label: "直播" },
  ];
  questionStatus = (status: number) => {
    switch (status) {
      case 0:
        return "待回复";
      case 1:
        return "已回复";
      default:
        return "-";
    }
  };
  diseaseJoin = (arr: any) => {
    if (!arr) return "-";
    try {
      const parsedDisease = JSON.parse(arr);
      if (Array.isArray(parsedDisease)) {
        return JSON.parse(arr).join(",");
      }
    } catch (error) {
      return arr;
    }

    return "-";
  };
  moduleStatus = (status: number) => {
    switch (status) {
      case 100:
        return "科普";
      case 101:
        return "视频";
      case 102:
        return "直播";
      default:
        return "-";
    }
  };
  versionEnrollmentMaxValid() {
    if (isNaN(Number(this.addQuestionSave.enrollment_max))) {
      this.addQuestionSave.enrollment_max = "";
      this.$message.warning(`请输入数字`);
      return;
    }
    if (
      Number(this.addQuestionSave.enrollment_max) < 0 ||
      Number(this.addQuestionSave.enrollment_max) > 100 ||
      this.addQuestionSave.enrollment_max.indexOf(".") > -1 ||
      this.addQuestionSave.enrollment_max.indexOf("-") > -1 ||
      this.addQuestionSave.enrollment_max.indexOf("+") > -1
    ) {
      this.addQuestionSave.enrollment_max = "";
      this.$message.warning(`请输入不含任何符号的大于0小于等于100的正整数`);
      return;
    }
  }
  //清空对象的所有属性值
  clearObject(obj: any) {
    Object.keys(obj).map((item) => {
      obj[item] = "";
    });
    this.$nextTick(() => {
      (this.$refs.addMenuForm as Form).clearValidate();
    });
    return obj;
  }
  //时间格式处理
  dateFormat = function (row: any) {
    if (row === undefined) {
      return "-";
    }

    const moment = require("moment");
    return moment(row).format("YYYY-MM-DD HH:mm:ss");
  };

  // 数据
  tableData: Array<any> = [];
  //表格分页器变量
  pagination = {
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
    //当前是第几页
    page: 1,
  };

  dash(data: any) {
    if (data === null || data === "" || data === undefined) {
      data = "-";
    }
    return data;
  }

  mounted() {
    this.getBroadcastPatientList();
  }
  search() {
    this.$set(this.pagination, "page", 1);
    this.getBroadcastPatientList();
  }

  resetClick() {
    this.pagination.size = 10;
    this.pagination.page = 1;
    Object.keys(this.query).forEach((key: any) => {
      this.query[key] = null;
    });
    this.getBroadcastPatientList();
  }

  async getBroadcastPatientList() {
    const param = JSON.parse(JSON.stringify(this.query)) as any;

    param.cur_page = this.pagination.page;
    param.page_size = this.pagination.size;

    if (param.end_time) {
      // 结束时间添加时间为23:59:59;
      param.end_time = param.end_time + (24 * 60 * 60 * 1000 - 1000);
    }

    ApiBroadcastQuestionList(param).then((res) => {
      this.tableData = res.data;
      this.pagination.total = res.page.total_size;
      this.tableLoading = false;
    });
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.getBroadcastPatientList();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.getBroadcastPatientList();
  }

  async downloadButtonClick(row: any) {
    this.exportLoading = true;
    const param = JSON.parse(JSON.stringify(this.query)) as any;
    const data = await ApiBroadcastPatientExcelBefore(param);
    if (typeof data.success == "undefined") {
      const res = await ApiBroadcastPatientExcel(param);
      const objUrl = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      // a.download = `${this.mettingName}签到表.xlsx`;
      document.body.appendChild(a);
      a.href = objUrl;
      a.click();
      a.remove();
      this.$message.success("文件下载中");
      this.exportLoading = false;
    } else {
      this.exportLoading = false;
    }
  }
  goDetail(row: any) {
    console.log(row);
    breadcrumb.push(
      this,
      "/liveQuestion/detail",
      "详情",
      // { id: row.id,
      //   broadcast_question_id: row.broadcast_question_id
      // },
      { id: row.id },
      true
    );
  }

  goDetele(row: any) {
    console.log(row.id);
    this.$confirm("确认删除嘛？", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      ApiBroadcastQuestionDelete(row.id).then((res) => {
        if (res.success) {
          this.$message.success("删除成功");
          this.getBroadcastPatientList();
        }
      });
    });
  }
  addEvent(row: any) {
    this.detailDialog = true;
  }
  closeDialog() {
    this.clearObject(this.addQuestionSave);
    this.detailDialog = false;
    this.$nextTick(() => {
      (this.$refs.addMenuForm as Form).clearValidate();
    });
  }
  clickAdd() {
    const formRef = this.$refs["addMenuForm"] as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        this.addLoading = true;
        ApiBroadcastQuestionSave(this.addQuestionSave).then((res) => {
          this.addLoading = false;
          if (res.success) {
            this.$message.success("新增成功！");
            this.getBroadcastPatientList();
            this.clearObject(this.addQuestionSave);
            this.detailDialog = false;
          }
        });
      }
    });
  }
}
